<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="dialog"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline"> {{ Task.title }}</span>
          </v-card-title>
          <v-card-subtitle>
            {{ Task.message }}
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    autocomplete="off"
                    type="text"
                    dense
                    v-model="comment"
                    label="Comment"
                    :rules="[
                      (v) =>
                        !!v ||
                        Task.event_type == 0 ||
                        Task.event_type == 2 ||
                        'Comment required !!',
                    ]"
                    @focus="$event.target.select()"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="red darken-1"
              @click.native="save(0)"
              v-if="Task.event_type == 1"
            >
              {{ Task.status_no_name ? Task.status_no_name : "Reject" }}
            </v-btn>
            <v-btn color="green darken-1" @click.native="save(1)">
              {{
                Task.status_yes_name
                  ? Task.status_yes_name
                  : Task.event_type == 1
                  ? "Accept"
                  : "Complete"
              }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>

            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click.native="cancel"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import COMPLETE_TASK_USER from "../graphql/Tasks/COMPLETE_TASK_USER.gql";
export default {
  components: {},
  name: "taskcloseform",
  props: {},
  data: () => ({
    progress: false,
    valid: true,
    dialog: false,
    resolve: null,
    reject: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    comment: null,
    kq: 100,
    Task: {},
  }),

  computed: {},
  watch: {},

  created() {},
  mounted() {},

  methods: {
    open(Task) {
      this.dialog = true;
      this.Task = Task;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async save(decision) {
      if (this.$refs.form.validate() && this.Task) {
        this.progress = true;
        let v = {
          id: this.Task.id,
          decision: this.Task.event_type == 1 ? decision : null,
          comment: this.comment,
        };
        let r = await this.maj(COMPLETE_TASK_USER, v, true);
        if (r) {
          this.progress = false;
          this.resolve(r.CompleteTaskUser);
          this.$emit("completed");
          this.dialog = false;
        }
      }
    },
  },
};
</script>
